<template>
  <div class="mt-5"  >
    <div class="mb-10" v-show="showForm">
      <h2 class="text-center success--text">
        <v-icon color="white" class="">mdi-clipboard-text</v-icon> ACCEPTANCE FORM
      </h2>
      <v-alert  class="text-center white--text" color="error">
          Complete the acceptance form below. All fields are required and important. <br>
          If you are not able to submit the form, you can <v-btn text href="/docs/ati-2021-acceptance-form.docx" download small><v-icon>mdi-download</v-icon>download the form</v-btn> and follow the instruction in it.
      </v-alert>

      <v-form lazy-validation ref="dform" v-model="validForm" @submit.prevent>
        <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.application_no"
          @input="dform.application_no = dform.application_no.toUpperCase()"
          label="Application Number:"
          outlined
          dense
          :rules="rules.required"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.fullname"
          label="Full Name (Surname First):"
          outlined
          dense
          :rules="rules.required"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.dob"
          type="date"
          label="Date of Birth:"
          outlined
          dense
          :rules="rules.required"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.age"
          label="Age:"
          outlined
          dense
          :rules="rules.required"
          required
          type="number"
          max="40"
          min="18"
          counter="2"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-textarea
          v-model.trim="dform.address"
          label="Residential Address:"
          outlined
          auto-grow
          rows="1"
          required
          :rules="rules.required"
        >
        </v-textarea>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.phoneno"
          type="number"
          maxlength="11"
          counter="11"
          label="Phone Number:"
          outlined
          dense
          :rules="rules.required"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.email"
          label="E-mail Address:"
          outlined
          type="email"
          dense
          :rules="rules.required"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-textarea
          v-model.trim="dform.qualification"
          label="Highest Qualification with date:"
          outlined
          auto-grow
          rows="1"
          :rules="rules.required"
          required
        >
        </v-textarea>
      </v-col>  
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.salvation_year"
          label="Year of Salvation:"
          outlined
          type="number"
          counter="4"
          dense
          :rules="rules.required"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.trim="dform.discipler_name"
          label="Name of Discipler:"
          outlined
          dense
          :rules="rules.required"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-radio-group
          v-model.trim="dform.acceptance"
          dense
          row
        >
          <span class="mr-3"
            >Are you accepting the offer of admission given to you by ATI?
          </span>
          <v-radio label="Yes" value="Yes" color="red"></v-radio>
          <v-radio label="No" value="No" color="red"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-radio-group
          v-model.trim="dform.abide"
          dense
          row
        >
          <span class="mr-3"
            >Are you willing to abide by the conditions attached to the admission stated in the admission letter?
          </span>
          <v-radio label="Yes" value="Yes" color="red"></v-radio>
          <v-radio label="No" value="No" color="red"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-radio-group
          v-model.trim="dform.resumption"
          dense
          row
        >
          <span class="mr-3"
            >Will you be willing to resume on the stipulated date of resumption?
          </span>
          <v-radio label="Yes" value="Yes" color="red"></v-radio>
          <v-radio label="No" value="No" color="red"></v-radio>
        </v-radio-group>
      </v-col>

    </v-row>
    <v-row justify="center">
      <v-btn
        v-if="dform.id"
        color="success"
        @click="saveChanges"
        class="ma-3"
        :loading="loading"
      >
        Save Changes
      </v-btn>
      <v-btn
       v-else
        :disabled="!validForm"
          color="success"
          @click="submitForm"
          class="ma-3"
          :loading="loading"
        >
          Submit
        </v-btn>
    </v-row>
      </v-form>
    
    </div>

    <v-alert prominent type="success" border="left" v-if="applicationSubmitted">
      <v-row align="center">
        <v-col class="grow">
          Your Application is Submitted Successfully. You can click the close button.
        </v-col>
        <v-col class="shrink">
          <v-btn to="/" color="primary">close</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins"

export default {
  name: "Acceptance",

  mixins: [snackMixin],

  props: {
    editUser: {
      type: Object,
      default: null,
    },
  },
  
  data() {
    return {
      showForm: true,
      validForm: true,
      user: {},
      applicationSubmitted: false,
      rules:{
        required:[v => !!v || 'This is required']
      },
      dform: {
        id: "",
        application_no: "",        
        fullname: "",
        dob: "",
        age: "",
        address: "",
        phoneno: "",
        email: "",
        qualification: "",
        salvation_year: "",
        discipler_name: "",        
        acceptance: "",
        abide: "",
        resumption: "",
      }
      
    };
  },
  computed: {
    formId(){
      let uid = this.user.id && this.user.id
      let eid = this.editUser && this.editUser.id
      return uid || eid
    },
    applicationNo(){
      let uno = this.user.application_no && this.user.application_no
      let eno = this.editUser && this.editUser.application_no
      return uno || eno
    },
    admitted(){
      return this.editUser && this.editUser.admitted == 1;
    }
  },
  methods: {
    submitForm() {
      // Do Validation here
      if(!this.$refs.dform.validate()){return}
      this.loading = true;
      // console.log(this.dform)
      apiClient
        .post("/acceptance", this.dform)
        .then((res) => {
          // console.log(res.data);
          if (res.data.ok) {            
            this.showForm = false;
            this.applicationSubmitted = true;
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },
    saveChanges() {
      // this.dform.id = this.formId;
      // Do Validation here
      if(!this.$refs.dform.validate()){return}
      this.loading = true;
      apiClient
        .put("/acceptance", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.showForm = false;
            this.applicationSubmitted = true;
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },
  },
};
</script>
